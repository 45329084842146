import fs from 'fs';
import crypto from 'crypto';
import path from 'path';
import { fileURLToPath } from 'url';

const __dirname = path.dirname(fileURLToPath(import.meta.url));
const OUTPUT_DIR = path.join(__dirname, '../.output/public/');
const HEADERS_FILE = path.join(OUTPUT_DIR, '_headers');

async function findScripts() {
    /* Updates Netlify _headers file with SHA hash of Nuxt's hydration script.
    Required for static deployments since Nuxt's built-in CSP doesn't handle Netlify's header format. 
    We dont need to scan all files since we are not using strict-dynamic CSP. */
    const indexHtmlPath = path.join(OUTPUT_DIR, 'index.html');
    const scriptHashes = new Set();

    if (!fs.existsSync(indexHtmlPath)) {
        console.error('HTML file not found:', indexHtmlPath);
        return scriptHashes;
    }

    const htmlContent = fs.readFileSync(indexHtmlPath, 'utf-8');
    const scriptRegex = /<script[^>]*>([\s\S]*?)<\/script>/g;
    let match;

    while ((match = scriptRegex.exec(htmlContent)) !== null) {
        const scriptContent = match[1].trim();
        if (scriptContent && scriptContent.startsWith('window.__NUXT__=')) {
            const hash = crypto
                .createHash('sha256')
                .update(scriptContent)
                .digest('base64');
            scriptHashes.add(`'sha256-${hash}'`);
            break;
        }
    }
    return scriptHashes;
}

async function updateCSPHeader() {
    const scriptHashes = await findScripts().catch(error => {
        console.error('Error finding script hashes:', error);
        return null;
    });

    if (!scriptHashes) return;

    if (!fs.existsSync(HEADERS_FILE)) {
        console.error('_headers file not found at:', HEADERS_FILE);
        return;
    }

    let headers = fs.readFileSync(HEADERS_FILE, 'utf-8');
    const scriptRegex = /(script-src[^;]+);/;
    
    headers = headers.replace(scriptRegex, (match) => {
        return `${match.slice(0, -1)} ${[...scriptHashes].join(' ')};`;
    });

    fs.writeFileSync(HEADERS_FILE, headers);
}

updateCSPHeader();
